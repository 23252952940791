<template>
  <div class="flex gap-2 sm:gap-4 text-sm">
    <ul class="flex-1 list-none max-h-48 text-heroblue font-semibold grid grid-cols-1 gap-1.5 sm:gap-2.5 overflow-y-auto scrollbar-light pr-2 -mr-2" ref="monthPickerEl">
      <li
        v-for="month in months"
        class="cursor-pointer px-3 py-3 flex-center transition-colors rounded border border-gray-200 leading-none"
        :class="[
          {'bg-heroblue text-white font-semibold': month === selection.month},
          {'hover:bg-black/5 border-gray-300': month !== selection.month}
        ]"
        @click="setMonth(month)"
        :data-month-value="month.name"
      >
        <span>{{month.name}}</span>
      </li>
    </ul>

    <u-divider orientation="vertical" :ui="{border: {base: 'dark:border-gray-200'}}" />

    <ul class="list-none text-charcoal grid grid-cols-1 gap-1.5 sm:gap-2.5 overflow-y-auto max-h-48 relative text-center scrollbar-light pr-2 -mr-2" ref="yearPickerEl">
      <li
        v-for="year in years"
        class="cursor-pointer flex-center px-8 py-3 transition-colors border border-gray-200 leading-none"
        :class="[
          {'bg-heroblue text-white font-semibold': year === selection.year},
          {'hover:bg-black/5 border-gray-300': year !== selection.year}
        ]"
        @click="setYear(year)"
        :data-year-value="year"
      >
        {{year}}
      </li>
    </ul>
  </div>
</template>

<script setup>

  const props = defineProps({
    yearRange: {
      type: Array
    }
  });

  const dayJs = useDayjs();
  const dateRange = defineModel();
  const months = dayJs[dayJs.locale()].months.map((month, index) => ({
    name: month,
    index
  }));
  const years = Array.from(Array((props.yearRange[1] - props.yearRange[0]) + 1), (n, i) => props.yearRange[1] - i);
  const selection = computed({
    get() {
      const theDate = dateRange.value?.val ? dayJs(dateRange.value?.val[0]) : dayJs();

      return {
        month: months.find(month => month.index === theDate.utc().month()),
        year: theDate.utc().year()
      }
    },
    set({month, year}) {
      dateRange.value.val = [
        dayJs().utc().year(year).month(month.index).startOf('month'),
        dayJs().utc().year(year).month(month.index).endOf('month')
      ];
    }
  });

  function setMonth(month) {
    selection.value = {
      month,
      year: selection.value.year
    };
  }

  function setYear(year) {
    selection.value = {
      month: selection.value.month,
      year
    };
  }

  const monthPickerEl = ref();
  const yearPickerEl = ref();

  onMounted(async () => {
    //note: needed to update the dateRange model to the appropriate range
    selection.value = {
      month: selection.value.month,
      year: selection.value.year
    };

    // Scroll to the selected year
    const yearItemEl = yearPickerEl.value.querySelector(`[data-year-value="${selection.value.year}"]`);
    if (yearItemEl) {
      yearItemEl.scrollIntoView({ behavior: 'instant', block: 'start' });
    }

    // Scroll to the selected month
    const monthItemEl = monthPickerEl.value.querySelector(`[data-month-value="${selection.value.month.name}"]`);
    if (monthItemEl) {
      monthItemEl.scrollIntoView({ behavior: 'instant', block: 'start' });
    }
  });

</script>
