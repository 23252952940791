import { useStorage } from '@vueuse/core';

function createComposable({id, defaultSize}) {
  const storageKey = `FILE_LIST_GRID_SIZE${(id && id !== 'default') ? `-${id}` : ''}`;

  const state = {
    gridSize: useStorage(storageKey, defaultSize || FILE_GRID_SIZES.md)
  };

  return {
    size: state.gridSize,
    setSize(size) {
      state.gridSize.value = size;
    }
  };
}

const instanceMap = new Map();

export function useFileListGrid({id, defaultSize} = {}) {
  const key = id || 'default';

  if (!instanceMap.get(key)) {
    instanceMap.set(key, createComposable({id, defaultSize}));
  }

  return instanceMap.get(key);
}
