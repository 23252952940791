<template>
  <div class="p-2 w-64 max-w-64">

    <p class="break-long-string text-white font-semibold text-sm">{{file.name}}</p>

    <u-divider class="my-2" />

    <div
      class="flex text-sm justify-between text-gray-200"
      v-if="file.is.photo || file.is.video || file.is.audio || file.is.document"
      v-html="`<span>${fuzzyDate.getFormattedString({date: file.taken_at, type: file.taken_at_date_type}).replace(/\n/, '</span><span>')}</span>`"
    />

    <div class="flex text-sm justify-between text-gray-200">
      <span>{{file.type}} <template v-if="!file.is.project">({{file.file_extension}})</template></span>
      <span v-if="file.width && file.height && !file.is.document">{{file.width}}x{{file.height}}</span>
    </div>
    <div class="flex text-sm justify-between text-gray-200">
      <span>{{file.file_size_gb}}</span>
      <span class="text-gray-200 text-sm" v-if="file.duration">{{formatDuration(file.duration)}}</span>
    </div>


    <template v-if="file.is.ownedByCurrentUser">
      <u-divider class="my-2" />
      <ul class="flex flex-col gap-1">
        <li>
          <u-button
            @click="toggleFavorite"
            color="gray"
            :loading="isFavoriteLoading"
            :icon="isFavorite ? COMMON_ICONS.favoriteFill : COMMON_ICONS.favoriteLine"
            :label="isFavorite ? 'Remove Favorite' : 'Add to Favorites'"
            :ui="{
          base: 'w-full',
          icon: {
            base: isFavorite && !isFavoriteLoading ? 'text-red-500' : 'text-gray-500',
          }
        }"
          />
        </li>
      </ul>

    </template>

  </div>

</template>

<script setup>
import {makeFilesStore, useFilesStore} from '~/stores/files.js';

const props = defineProps({
    file: Object,
    filesStoreId: String
  });

  const filesStore = props.filesStoreId
    ? makeFilesStore(props.filesStoreId)()
    : useFilesStore();

  const storeFile = computed(() => filesStore.files.find(f => f.id === props.file.id));
  const isFavorite = computed(() => storeFile.value?.favorite);

  const fuzzyDate = useFuzzyDate();

  const isFavoriteLoading = ref(false);
  async function toggleFavorite() {
    try {
      isFavoriteLoading.value = true;
      await useFileActions().toggleFavorite({file: storeFile.value, filesStoreId: props.filesStoreId});
    } catch (e) {
      useErrorToast().add();
    } finally {
      isFavoriteLoading.value = false;
    }
  }
</script>
