<template>
  <u-button-group size="sm">
    <u-button
      icon="i-material-symbols-background-grid-small-sharp"
      :variant="gridSize === FILE_GRID_SIZES.sm ? 'solid' : 'outline'"
      color="charcoal"
      @click="setGridSize(FILE_GRID_SIZES.sm)"
      :ui="{icon: {base: 'scale-125'}}"
    />
    <u-button
      icon="i-oi-grid-three-up"
      :variant="gridSize === FILE_GRID_SIZES.md ? 'solid' : 'outline'"
      color="charcoal"
      @click="setGridSize(FILE_GRID_SIZES.md)"
    />
    <u-button
      icon="i-oi-grid-two-up"
      :variant="gridSize === FILE_GRID_SIZES.lg ? 'solid' : 'outline'"
      color="charcoal"
      @click="setGridSize(FILE_GRID_SIZES.lg)"
    />
  </u-button-group>
</template>

<script setup>

  const props = defineProps({
    id: String,
    defaultSize: Number
  });

  const grid = useFileListGrid({id: props.id, defaultSize: props.defaultSize});
  const gridSize = computed(() => grid.size.value);
  function setGridSize(size) {
    grid.setSize(size);
  }

</script>
