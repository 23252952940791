<template>
  <div class="album-delete-modal">
    <core-modal-header v-if="isMulti">Delete <span class="font-semibold">{{count}} Selected</span> Albums?</core-modal-header>
    <core-modal-header v-else>Delete Album <span class="font-semibold">{{albumName}}</span>?</core-modal-header>

    <core-modal-body>
      <u-alert
        :icon="COMMON_ICONS.alert"
        color="red"
        title="This action cannot be undone."
        :description="description"
      />
    </core-modal-body>

    <core-modal-footer>
      <u-button color="charcoal" size="xl" variant="soft" @click="modal.close()">Cancel</u-button>
      <u-button @click="confirm" color="red" size="xl" :loading="isSaving">Delete {{isMulti ? 'Albums' : 'Album'}}</u-button>
    </core-modal-footer>

  </div>
</template>

<script setup>
  const emit = defineEmits(['success']);
  const props = defineProps({
    albums: Array,
    modal: Object
  });

  const count = props.albums.length;
  const isMulti = count > 1;
  const albumName = html2text(props.albums[0].name);
  const isSaving = ref(false);

  const description = isMulti
    ? 'Deleting the selected albums will remove all nested albums within them and break any associated share links and/or QR codes, but your files will remain safely in your Gallery.'
    : 'Deleting this album will remove all nested albums within it and break the associated share link and/or QR code, but your files will remain safely in your Gallery.';


  async function confirm() {
    try {
      isSaving.value = true;
      await useAlbumsStore().destroyAlbums({albums: props.albums});
      emit('success');
    } catch (e) {
      useErrorToast().add();
    } finally {
      isSaving.value = false;
    }
  }
</script>
