<template>
  <vue-date-picker
    v-model="internalDate"
    auto-apply
    format="M/d/yyyy"
    timezone="UTC"
    utc="preserve"
    month-name-format="long"
    inline
    week-start="0"
    :enable-time-picker="false"
    :year-range="yearRange"
    :clearable="false"
    :offset="4"
    :is-24="false"
    class="flex-center"
  />

  <!-- todo: offset="4" should be checked for users in other time zones -->

</template>

<script setup>
  import VueDatePicker from '@vuepic/vue-datepicker';

  const props = defineProps({
    yearRange: {
      type: Array
    }
  });

  const dayJs = useDayjs();
  const dateRange = defineModel();
  const internalDate = computed({
    get() {
      return Array.isArray(dateRange.value?.val) ? dateRange.value.val[0] : dateRange.value?.val;
    },
    set(value) {
      dateRange.value.val = [
        dayJs(value).utc().startOf('day'),
        dayJs(value).utc().endOf('day')
      ];
    }
  });
</script>
