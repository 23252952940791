<template>
  <ul class="list-none text-base text-charcoal overflow-y-auto max-h-48 grid grid-cols-3 gap-1.5 sm:gap-2.5 scrollbar-light pr-2 sm:-mr-2" ref="decadePickerEl">
    <li
      v-for="decade in decades"
      class="cursor-pointer flex-center text-sm px-4 py-3 transition-colors border border-gray-200 leading-none"
      :class="[
        {'bg-heroblue text-white font-semibold': decade === selection},
        {'hover:bg-black/5 border-gray-300': decade !== selection}
      ]"
      @click="setDecade(decade)"
      :data-decade-value="decade"
    >
      {{decade}}s
    </li>
  </ul>
</template>

<script setup>
  const props = defineProps({
    yearRange: {
      type: Array
    }
  });

  const dayJs = useDayjs();
  const dateRange = defineModel();
  const decades = Array.from(Array(Math.ceil((props.yearRange[1] - props.yearRange[0]) / 10)), (n, i) => (Math.floor(props.yearRange[1] / 10) * 10) - (i * 10));
  const selection = computed({
    get() {
      const theDate = dateRange.value?.val ? dayJs(dateRange.value?.val[0]) : dayJs();
      const theYear = theDate.utc().year();

      return decades.find(decade => decade <= theYear);
    },
    set(value) {
      dateRange.value.val = [
        dayJs().utc().year(value).startOf('year'),
        dayJs().utc().year(value + 9).endOf('year')
      ];
    }
  });

  function setDecade(decade) {
    selection.value = decade;
  }

  const decadePickerEl = ref();

  onMounted(() => {
    selection.value = selection.value; //note: needed to update the dateRange model to the appropriate range

    const itemEl = decadePickerEl.value.querySelector(`[data-decade-value="${selection.value}"]`);
    if (itemEl) {
      itemEl.scrollIntoView({ behavior: 'instant', block: 'start' });
    }

  });

</script>
