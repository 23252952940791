<template>
  <vue-date-picker
    v-model="internalDate"
    :enable-time-picker="hasTimePicker"
    time-picker-inline
    auto-apply
    format="M/d/yyyy"
    timezone="UTC"
    utc="preserve"
    month-name-format="long"
    inline
    week-start="0"
    :year-range="yearRange"
    :clearable="false"
    :offset="4"
    :is-24="false"
    class="flex-center"
  />

  <!-- todo: offset="4" should be checked for users in other time zones -->
</template>

<script setup>
  import VueDatePicker from '@vuepic/vue-datepicker';

  const props = defineProps({
    yearRange: {
      type: Array
    },
    hasTimePicker: {
      type: Boolean,
      default: true
    }
  });

  const dayJs = useDayjs();
  const dateRange = defineModel();
  const internalDate = computed({
    get() {
      return dateRange.value?.val && dayJs(dateRange.value.val[0]);
    },
    set(value) {
      dateRange.value.val = [
        dayJs(value)
      ];
    }
  });

</script>
