<template>
  <div class="file-list-grid h-full overflow-hidden" ref="listWrapper" @contextmenu.prevent>
    <client-only>
      <VirtualCollection
        :cellSizeAndPositionGetter="cellSizeAndPositionGetter"
        :collection="collectionFilesWithPositions"
        :height="listHeight"
        :width="listWidth"
        ref="listElem"
        class="scroll-smooth"
        :container-padding-bottom="50"
        :header-slot-height="headerSlotHeight"
      >

        <template #header>
          <div ref="header" class="relative mr-[3px] lg:mr-[23px] isolate">
            <slot name="header" />

            <div
              class="absolute -inset-0.5 bg-white/50 backdrop-blur-[2px] z-10 transition-opacity duration-300"
              :class="selection.hasAny.value ? 'opacity-1 pointer-events-auto': 'opacity-0 pointer-events-none'"
            />
          </div>

          <slot name="under-header" />

        </template>

        <template #cell="{data}">
          <div v-if="data.isPlaceholder" />
          <div
            v-else-if="data.isGroupHeader"
            class="h-full flex items-center gap-1 select-none group"
            :class="{
              'is-selected': fileGroupSelection.has(data),
              'has-selection': hasSelection
            }"
          >

            <span class="text-sm cursor-default font-semibold mt-px">{{data.name}}</span>
            <core-select-toggle
              v-if="!disableSelection"
              class="opacity-0 group-hover:opacity-100 group-[.has-selection]:opacity-100 group-[.is-selected]:opacity-100"
              dark
              :selected="fileGroupSelection.has(data)"
              @update:selected="fileGroupSelection.toggle({group: data})"
            />
          </div>

          <file-list-item
            v-else
            :file="data"
            :key="data.id"
            :selected="selection.has(data)"
            @update:selected="toggleSelection(data)"
            :selection-candidate="selection.isCandidate(data)"
            :selectable="!disableSelection"
            @click="onItemClick(data)"
            @item-context="setContextMenu(data)"
            @mouseover="selection.updateSelectionCandidates({item: data, collection: files})"
          >
            <template #optional-items="{file}">
              <slot name="list-item-optional-items" :file="file" />
            </template>
          </file-list-item>

        </template>

      </VirtualCollection>
    </client-only>

    <core-context-menu v-if="listElem" :scroller="listElem" v-model="currentContextItem" v-model:isOpen="isOpen">
      <file-list-item-context-menu :file="currentContextItem" :files-store-id="filesStoreId" />
    </core-context-menu>

  </div>
</template>

<script setup>
import {storeToRefs} from 'pinia';
import VirtualCollection from 'vue-virtual-collection/src/VirtualCollection.vue';
import throttle from 'lodash.throttle';
import {useFileGroupSelection} from '~/composables/file/group-selection.js';
import {useFileListGrid} from "~/composables/file/grid.js";

//component conf
const emit = defineEmits(['approach-bottom', 'list-item-click']);
const props = defineProps({
  files: Array,
  filesStoreId: String,
  groupFiles: Boolean,
  disableApproachBottom: Boolean,
  disableSelection: Boolean,
  disableContextMenu: Boolean,
  disableBackToTop: Boolean,
  forceGroupsOnNewRows: Boolean,
  getGroupFiles: Function, //note: this will be passed in to the groupSelection composable. Receives a groupId and returns all files that belong to that group
  gridSizeId: String
});

const config = {
  fileGap: 3,
  groupHorizontalGap: 25,
  groupVerticalGap: 25,
  groupHeaderHeight: 30,
  groupHeaderWidth: 220,
  scrollbarWidth: 30,
  scrollbarWidthSm: 9,
  emitOnDistanceFromBottom: 500
};

const filesStore = useFilesStore();
const {collectionDescriptor} = storeToRefs(filesStore);

const header = ref();
const {height: headerSlotHeight} = useElementSize(header);

const grid = useFileListGrid({id: props.gridSizeId});
const initialRowHeight = computed(() => grid.size.value);

//files mapping
const collectionFiles = computed(() => {
  //files need to be grouped
  if (props.groupFiles) {
    let currentGroup = null;

    return props.files.reduce((grouped, file) => {

      if (file.group?.id !== currentGroup?.data.id) {
        currentGroup = {
          data: {
            name: file.group.name,
            fieldVal: file.group.fieldVal, //todo: may cleanup
            id: file.group.id,
            fileCount: 0,
            isGroupHeader: true
          }
        };
        grouped.push(currentGroup);
      }

      grouped.push({data: file});
      if (currentGroup) {
        currentGroup.data.fileCount++;
      }

      return grouped;
    }, []);
  }

  //return non-grouped files
  return props.files.map(file => ({data: file}));
});

const currentContextItem = ref(null);
const isOpen = ref(false);
async function setContextMenu(file) {
  if (!props.disableContextMenu) {
    isOpen.value = false;
    await nextTick();
    isOpen.value = true;
    currentContextItem.value = file;
  }
}

//display
const listWrapper = ref(null);
const listElem = ref(null);

const listScrollThrehold = useEventBus(`${FILE_LIST_SCROLL_THRESHOLD_EVENT}${props.filesStoreId ? `-${props.filesStoreId}` : ''}`);
const {y: scrollY} = useScroll(listElem, {
  throttle: 100,
  behavior: 'smooth'
});

watch(scrollY, () => {
  listScrollThrehold.emit(scrollY.value > FILE_LIST_SCROLL_THRESHOLD_DEFAULT);
});

const {width: listWidth, height: listHeight} = useElementSize(listWrapper);
const {lg: largeScreen} = useScreenSize();

const itemsPositions = computed(() => {
  const placeholder = {
    x: 0,
    y: 0,
    width: 0,
    height: 0
  };

  if (!props.files?.length) {
    return [placeholder];
  }
  if (!listWidth.value || !listHeight.value) {
    return Array.from(Array(collectionFiles.value.length)).map(() => placeholder);
  }

  const rowWidth = listWidth.value - (largeScreen.value ? config.scrollbarWidth : config.scrollbarWidthSm);

  const tracker = {
    prevRowHeight: 0,
    prevY: (props.groupFiles ? -config.groupVerticalGap : 0),
    positionedIndex: -1
  };

  const buildRow = () => {
    const hasGroupHeader = collectionFiles.value[tracker.positionedIndex + 1].data.isGroupHeader;
    let isFullRow = false;

    const {cells: rowCells, rowItemCellsHeight} = (() => {
      const cells = [];
      let usedRowWidth = hasGroupHeader ? -config.groupHorizontalGap : -config.fileGap;
      let hasDoneFirstGroupItem = false; //note: for checking if group should be forced to new row

      while (usedRowWidth < rowWidth) {
        const currentItem = collectionFiles.value[tracker.positionedIndex + 1 + cells.length];

        if (!currentItem || (!hasGroupHeader && currentItem.data.isGroupHeader) || (props.forceGroupsOnNewRows && hasDoneFirstGroupItem && currentItem.data.isGroupHeader)) {
          break;
        }

        if (currentItem.data.isGroupHeader) {
          hasDoneFirstGroupItem = true;

          cells.push({
            isGroupHeader: true,
            fileCount: currentItem.data.fileCount
          });
          usedRowWidth += config.groupHorizontalGap;
        } else {
          const [width, height] = (() => {
            const w = (currentItem.data.width || initialRowHeight.value);
            const h = (currentItem.data.height || initialRowHeight.value);

            return currentItem.data.images?.rotation % 180
              ? [h, w]
              : [w, h];
          })();
          const scaleFactor = initialRowHeight.value / height;

          const currentCell = {
            width: width * scaleFactor,
            height: initialRowHeight.value,
            aspect: width / height
          };

          cells.push(currentCell);
          usedRowWidth += currentCell.width + (cells[cells.length - 2]?.isGroupHeader ? 0 : config.fileGap);
        }
      }

      //check if we have to remove the last item as it might have gone over the allowed row width
      if (usedRowWidth > rowWidth && cells.filter(cell => !cell.isGroupHeader).length > 1) {
        const lastCell = cells.pop();
        usedRowWidth -= lastCell.width + config.fileGap;

        //in case the item we just removed was the first in a group
        if (cells[cells.length - 1].isGroupHeader) {
          cells.pop();
          usedRowWidth -= config.groupHorizontalGap;
        }
      }

      //check if all the files from the last group can fit
      if (hasGroupHeader) {
        const lastGroupIndex = cells.findLastIndex(cell => cell.isGroupHeader);

        //note: checking after index 0 because that's where the initial group header item is
        if (lastGroupIndex > 0) {
          const lastGroupCell = cells[lastGroupIndex];
          const lastGroupItemsPlaced = cells.length - 1 - lastGroupIndex;

          if (lastGroupItemsPlaced < lastGroupCell.fileCount) {
            const removedCells = cells.splice(lastGroupIndex, cells.length - lastGroupIndex);
            usedRowWidth -= removedCells.reduce((total, cell) => total + (cell.isGroupHeader ? config.groupHorizontalGap : (cell.width + config.fileGap)), 0)
          }
        }
      }

      const nextItem = collectionFiles.value[tracker.positionedIndex + 1 + cells.length];
      isFullRow = ((rowWidth - usedRowWidth) < (initialRowHeight.value / 2 )) ||
        (nextItem && !nextItem.data.isGroupHeader);


      let adjustedHeight = initialRowHeight.value;

      //adjust row length to fit if necessary
      if (isFullRow) {
        const totalWidthAdjustment = rowWidth - usedRowWidth;
        const nonGroupHeaderCells = cells.filter(cell => !cell.isGroupHeader);
        const {width: totalWidthTakenByFiles, aspect: totalAspectRatioForFiles} =
          nonGroupHeaderCells
            .reduce((total, cell) => {
              total.width += cell.width;
              total.aspect += cell.aspect;

              return total;
            }, {width: 0, aspect: 0});

        adjustedHeight = (totalWidthTakenByFiles + totalWidthAdjustment) / totalAspectRatioForFiles;

        nonGroupHeaderCells.forEach(cell => {
          cell.height = adjustedHeight;
          cell.width = cell.height * cell.aspect;
        });
      }

      return {cells, rowItemCellsHeight: adjustedHeight};
    })();

    //position the items in the row
    const rowY = tracker.prevY + tracker.prevRowHeight + (hasGroupHeader ? config.groupVerticalGap : config.fileGap);

    const rowItemsY = rowY + (hasGroupHeader ? config.groupHeaderHeight : 0);
    const rowHeight = rowItemCellsHeight + (hasGroupHeader ? config.groupHeaderHeight : 0);

    tracker.prevRowHeight = rowHeight;
    tracker.prevY = rowY;

    //--
    let prevX = rowCells[0].isGroupHeader ? -config.groupHorizontalGap : -config.fileGap;
    let prevWidth = 0;
    let prevCell = null;

    return rowCells.map(cell => {
      const y = cell.isGroupHeader ? rowY : rowItemsY;
      const width = cell.isGroupHeader ? config.groupHeaderWidth : cell.width;
      const height = cell.isGroupHeader ? config.groupHeaderHeight : cell.height;
      let x = prevX;

      if (cell.isGroupHeader) {
        x += prevWidth + config.groupHorizontalGap;
      } else {
        x += prevCell?.isGroupHeader ? 0 : prevWidth + config.fileGap;
      }

      prevX = x;
      prevWidth = width;
      prevCell = cell;

      return {
        x,
        y,
        height,
        width
      };
    });
  };

  const positionedCells = [];

  while (tracker.positionedIndex < collectionFiles.value.length - 1) {
    const positionedRow = buildRow();
    positionedCells.push(...positionedRow);
    tracker.positionedIndex += positionedRow.length;
  }

  return positionedCells;
});

const collectionFilesWithPositions = computed(() => (
  collectionFiles.value?.length
    ? collectionFiles.value.map((file, index) => Object.assign({}, file, {position: itemsPositions.value[index]}))
    : [{data: {isPlaceholder: true}}]
  )
);

let itemIndexToScrollTo = 0; //tracks the index of the item we should scroll to on grid size change

function cellSizeAndPositionGetter(item, index) {
  return itemsPositions.value[index] || {};
}

//selection
const selection = useSelection();
const fileGroupSelection = useFileGroupSelection({getGroupFiles: props.getGroupFiles});

watch(
  () => props.groupFiles,
  newVal => {
    if (newVal) {
      selection.addSelectionChangeHandler(fileGroupSelection.updateGroupSelectionFromFiles);
      selection.addSelectionClearHandler(fileGroupSelection.clearGroupSelection);
    } else {
      selection.removeSelectionChangeHandler(fileGroupSelection.updateGroupSelectionFromFiles);
      selection.removeSelectionClearHandler(fileGroupSelection.clearGroupSelection);
    }
  },
  {
    immediate: true
  }
);

const hasSelection = computed(() => selection.hasAny.value);

//todo: revisit for context, we want errored files to be selectable
//const isDisabledChecker = file => file.status === 'errored';

function toggleSelection(file) {
  selection.toggle({item: file/*, isDisabledChecker*/});
}

//item
async function onItemClick(file) {
  if (selection.hasAny.value) {
    toggleSelection(file);
  } else {
    emit('list-item-click', file);
  }
}

//misc
const reloadEvent = useEventBus('files-reload-start');
let scrollPosition; //note: after onMounted runs this contains a ref that can control the list scroll position
let isScrollingFromGridSizeChange = false;

function resetScrollPosition() {
  scrollPosition.value = headerSlotHeight.value || 0;
}

const backToTop = useBackToTop();

onMounted(async () => {
  await nextTick();

  //scroll bottom
  const {y} = useScroll(listElem.value?.$el);
  scrollPosition = y;

  const detectApproachBottom = throttle(() => {
    if (props.disableApproachBottom || !listElem.value) {
      return;
    }

    const viewPortBottom = scrollPosition.value + listHeight.value;
    const scrollHeight = listElem.value.$el.scrollHeight;

    if (viewPortBottom >= scrollHeight - config.emitOnDistanceFromBottom) {
      emit('approach-bottom');
    }

  }, 100);

  watch(scrollPosition, () => {
    detectApproachBottom();

    //find index of the first item that is in the view before the grid size change
    if (!isScrollingFromGridSizeChange) {
      itemIndexToScrollTo = itemsPositions.value.findIndex(item => item.y > (scrollPosition.value - headerSlotHeight.value));
    }
  });

  watch(initialRowHeight, async () => {
    await nextTick();

    isScrollingFromGridSizeChange = true;

    //preserve the top items in vew between grid changes
    scrollPosition.value = itemsPositions.value[itemIndexToScrollTo].y + headerSlotHeight.value;

    if (!props.disableApproachBottom) {
      detectApproachBottom();
    }

    await waitFor(300);
    isScrollingFromGridSizeChange = false;
  });

  watch(collectionFiles, detectApproachBottom, {immediate: true});
  watch(() => props.disableApproachBottom, detectApproachBottom); //todo: test on existing lists

  watch(collectionDescriptor, async () => {
    await nextTick();

    scrollPosition.value = headerSlotHeight.value;
  });

  reloadEvent.on(resetScrollPosition);

  if (!props.disableBackToTop) {
    backToTop.bindToScroller({elem: listElem.value?.$el});
  }

});

onBeforeUnmount(() => {
  selection.clear();
  selection.removeSelectionChangeHandler(fileGroupSelection.updateGroupSelectionFromFiles);
  selection.removeSelectionClearHandler(fileGroupSelection.clearGroupSelection);

  reloadEvent.off(resetScrollPosition);

  if (!props.disableBackToTop) {
    backToTop.unbind();
  }
});

</script>

<style scoped lang="scss">
.file-list-grid {
  .vue-virtual-collection {
    @apply overflow-x-hidden scrollbar-light;
  }
}
</style>
