<template>
  <div>
    <div class="text-charcoal text-sm font-semibold text-center -mt-2.5 mb-1" v-if="internalRange?.length">{{internalRange[0].format('LL')}} - {{internalRange[1].format('LL')}}</div>
    <vue-date-picker
      v-model="internalRange"
      range
      :multi-calendars="{
        solo: true
      }"
      auto-apply
      format="M/d/yyyy"
      timezone="UTC"
      utc="preserve"
      month-name-format="long"
      inline
      week-start="0"
      :enable-time-picker="false"
      :year-range="yearRange"
      :clearable="false"
      :is-24="false"
    />
  </div>
</template>

<script setup>
  import VueDatePicker from '@vuepic/vue-datepicker';

  const props = defineProps({
    yearRange: {
      type: Array
    }
  });

  const dayJs = useDayjs();
  const dateRange = defineModel();
  const internalRange = computed({
    get() {
      return dateRange.value?.val;
    },
    set(value) {
      dateRange.value.val = [
        dayJs(value[0]).utc().startOf('day'),
        dayJs(value[1]).utc().endOf('day')
      ];
    }
  });

  onMounted(() => {
    if (internalRange.value) {
      internalRange.value = [
        internalRange.value[0].year(Math.max(dayJs(internalRange.value[0]).year(), props.yearRange[0])),
        internalRange.value[1].year(Math.min(dayJs(internalRange.value[1]).year(), props.yearRange[1]))
      ];
    }
  });

</script>
