<template>
  <div>
    <core-modal-header>
      Show/Hide People

      <div class="flex mt-3 gap-2 -mr-5 -mb-1 font-sans font-normal tracking-normal">
        <div class="sm:flex-1">
          <people-name-status-select v-model="namedStatus" />
        </div>
        <div class="flex items-center gap-2 flex-1 sm:flex-0 justify-end">
          <core-sort-select-menu v-if="!searchTerm" v-model="currentSort" :options="PEOPLE_SORT_OPTIONS" />
          <core-search-input
            placeholder="Search People"
            @search="updateSearchQuery"
            @clear="updateSearchQuery({q: ''})"
            :loading="pending"
          />
        </div>
      </div>
    </core-modal-header>

    <core-modal-body>
      <div class="flex-1 overflow-y-auto scrollbar-light -mr-2 sm:-mr-4 pr-2 sm:pr-4 scrollbar-gutter-stable max-h-[72vh]" ref="listEl">
        <transition-group name="f-slide-fade">
          <core-contextual-loading-box v-if="pending" key="loading" loading-text="Loading People" />
          <core-contextual-error-box v-else-if="error" key="error" :action="refresh" />

          <core-empty-state
            v-else-if="!people.length"
            heading="No People"
            description="No people were found that match your criteria."
            :icon="COMMON_ICONS.person"
          />

          <core-list v-else :items="people" :scroller-elem="listEl" :disable-approach-bottom="pending || isLoadingMore || !hasMore" @approach-bottom="getMore" container-class="grid grid-cols-3 xs:grid-cols-4 md:grid-cols-6" grid-gap-class="gap-3 sm:gap-6">
            <template #default="{ item: person }">
              <people-list-item
                :person="person"
                :key="person.id"
                disable-edit
                show-file-count
                @item-click="toggleItem(person)"
              />
            </template>
          </core-list>
        </transition-group>
      </div>

    </core-modal-body>

  </div>

</template>

<script setup>
  import {makePeopleStore} from '~/stores/people.js';
  import {storeToRefs} from 'pinia';
  import {useStorage} from '@vueuse/core';

  const listEl = ref();

  const peopleStoreId = 'showHidePeopleStore';
  const peopleStore = makePeopleStore(peopleStoreId)();
  const {people, hasMore} = storeToRefs(peopleStore);

  const namedStatus = ref(PEOPLE_NAMED_STATUS_OPTIONS[0]);

  const storedSort = useStorage(
    'core-web-ui-people-sort',
    PEOPLE_SORT_OPTIONS[0]
  );

  const currentSort = ref(storedSort.value);

  //search
  const searchTerm = ref();
  function updateSearchQuery({q}) {
    searchTerm.value = q;
  }

  const {refresh, pending, error} = await useLazyAsyncData(
    'show-hide-people',
    () => peopleStore.getPeople({
      displayStatus: [PEOPLE_DISPLAY_STATUS.visible, PEOPLE_DISPLAY_STATUS.hidden],
      search: searchTerm.value,
      namedStatus: namedStatus.value.param,
      order: currentSort.value.param
    }),
    {
      watch: [searchTerm, namedStatus, currentSort]
    }
  );

  const isLoadingMore = ref(false);

  async function getMore() {
    if (!hasMore.value) {
      return;
    }

    isLoadingMore.value = true;

    try {
      await peopleStore.getPeople({nextPage: true});
    } catch (e) {
      useErrorToast().add('There was an issue getting more people.');
    } finally {
      isLoadingMore.value = false;
    }
  }

  function toggleItem(person) {
    peopleStore.updatePerson({
      id: person.id,
      updates: {
        display_status: person.isVisible
          ? PEOPLE_DISPLAY_STATUS.hidden
          : PEOPLE_DISPLAY_STATUS.visible
      }
    });
  }

  onUnmounted(() => {
    peopleStore.clearPeople();
  });
</script>
