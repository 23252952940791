const state = {
  selected: ref(new Map())
};

/**
 * @param getGroupFiles: a function to be called to obtain all files belonging to a passed in groupId
 * @returns {any|boolean|{clearGroupSelection(): void, toggle({group: *}): Promise<void>, updateGroupSelectionFromFiles: (function({select: *, unselect: *}): void), has(*): *}}
 */

export function useFileGroupSelection({getGroupFiles = async (/*{groupId}*/) => {}} = {}) {
  const fileSelection = useSelection();

  const updateGroupSelectionFromFiles = ({select, unselect}) => {
    const affectedGroups = [...(select || []), ...(unselect || [])].reduce((affectedSet, file) => {
      affectedSet.add(file.group.id);

      return affectedSet;
    }, new Set());

    affectedGroups.forEach(async (groupId) => {
      const files = await getGroupFiles({groupId});
      const isGroupSelected = files.every(file => fileSelection.has({id: file.id}));
      const selectionState = state.selected.value.get(groupId) || false;

      if (selectionState !== isGroupSelected) {
        state.selected.value.set(groupId  , isGroupSelected);
      }
    });
  };

  return {
    async toggle({group}) {
      const files = await getGroupFiles({groupId: group.id});
      const payload = this.has(group)
        ? {unselect: files}
        : {select: files};

      fileSelection.update(payload);
    },
    has(group) {
      return state.selected.value.get(group.id) || false;
    },
    updateGroupSelectionFromFiles,
    clearGroupSelection() {
      state.selected.value.clear();
    }
  };
}
