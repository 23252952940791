<template>
  <div class="flex gap-2">
    <u-select-menu v-model="namedStatus" :options="PEOPLE_NAMED_STATUS_OPTIONS" :ui-menu="{width: 'w-40'}">
      <u-chip :show="namedStatus.param !== null">
        <core-responsive-button icon="i-ri-equalizer-line" :variant="namedStatus.param ? 'solid' : 'outline'" :color="namedStatus.param ? 'heroblue' : 'charcoal'">Filters</core-responsive-button>
      </u-chip>
    </u-select-menu>
    <u-button @click="namedStatus = PEOPLE_NAMED_STATUS_OPTIONS[0]" v-if="namedStatus.param !== null" :icon="COMMON_ICONS.close" trailing color="charcoal" variant="outline" class="hidden sm:flex">{{namedStatus.label}}</u-button>
  </div>
</template>

<script setup>

    const namedStatus = defineModel();

</script>
