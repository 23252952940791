<template>
  <div>
    <core-modal-header>Remove File{{files.length > 1 ? 's' : null}} from {{FILE_CONTEXTS_FRIENDLY[contextType]}}?</core-modal-header>

    <core-modal-body>
      <p v-html="mainText" />
      <u-alert
        v-if="contextType === FILE_CONTEXTS.person"
        class="mt-4"
        color="blue"
        :icon="COMMON_ICONS.information"
        title="This action is potentially destructive."
        description="Removing files from a person will permanently delete any associated facial recognition data."
      />

    </core-modal-body>

    <core-modal-footer>
      <u-button size="xl" color="charcoal" variant="soft" @click="close">Cancel</u-button>
      <u-button size="xl" @click="confirm" :loading="isSaving">Remove File{{files.length > 1 ? 's' : null}}</u-button>
    </core-modal-footer>
  </div>
</template>

<script setup>
  import {makeFilesStore} from '~/stores/files.js';

  const emit = defineEmits(['success']);

  const props = defineProps({
    files: {
      type: Array,
      default: () => []
    },
    context: Object,
    contextType: String,
    filesStoreId: String,
    modal: Object
  });

  const isSaving = ref(false);
  const filesStore = props.filesStoreId
      ? makeFilesStore(props.filesStoreId)()
      : useFilesStore();

  const count = computed(() => props.files.length);
  const isMulti = computed(() => count.value > 1,);
  const fileName = computed(() => count.value && html2text(props.files[0].name));
  const contextName = computed(() => {
    const fallback = props.contextType === FILE_CONTEXTS.person ? 'this person' : 'this container';
    return html2text(props.context?.name || fallback)
  });

  const mainText = computed(() => {
    const theCount = `the <strong><em>${count.value} selected files</em></strong>`;
    const theFileName = `<strong><em>${fileName.value}</em></strong>`;
    const theContainerName = `<strong><em>${contextName.value}</em></strong>`;

    return isMulti.value
      ? `Remove ${theCount} from ${theContainerName}?`
      : `Remove ${theFileName} from ${theContainerName}?`;
  });

  function close() {
    props.modal.close();
  }

  async function confirm() {
    if (isSaving.value) return;

    try {
      isSaving.value = true;
        const ids = props.files.map(f => f.id);

        await filesStore.removeFilesFromContext({
          ids,
          contextType: props.contextType,
          contextId: props.context.id
        });

      emit('success');
    } catch (e) {
      useErrorToast().add();
      close();
    }
  }
</script>
