<template>
  <u-select-menu v-model="model" :options="options" :ui-menu="{width: 'w-56'}" :placeholder="placeholder">
    <div class="hidden sm:flex items-center text-sm font-semibold gap-2 cursor-pointer flex-nowrap">
      <span class="text-text-light text-nowrap"><span class="capitalize">{{verb}}</span> by:</span>
      <span class="text-heroblue-700 text-nowrap">{{model.label || placeholder}}</span>
      <u-icon name="i-ri-arrow-down-s-line" class="text-heroblue-700 text-lg" />
    </div>

    <core-responsive-button icon="i-ri-sort-desc" variant="outline" color="charcoal" class="sm:hidden" />

  </u-select-menu>
</template>

<script setup>

  const props = defineProps({
    options: Array,
    placeholder: String,
    verb: {
      type: String,
      default: 'sort'
    }
  });

  const model = defineModel();
</script>
