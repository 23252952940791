<template>
  <section>
    <u-form :state="fileInfo" :schema="schema" @submit="save" ref="form" :validate-on="['submit']">
      <core-modal-header>
        Bulk Organize: {{files.length}} item(s)
      </core-modal-header>

      <core-modal-body>
        <u-alert
          v-if="!isSingleFileMode"
          color="heroblue"
          class="mb-4"
          :icon="COMMON_ICONS.information"
          title="Editing Multiple Files"
          description="Updating file info will overwrite existing details. Blank fields won't be altered."
        />

        <u-form-group label="Edit Name" name="name" :required="isSingleFileMode" class="mb-4">
          <u-input v-model="fileInfo.name" placeholder="Enter Name" size="lg" />
        </u-form-group>

        <u-form-group label="Edit Description" class="mb-4">
          <u-textarea placeholder="Enter Description" size="lg" v-model="fileInfo.description" />
        </u-form-group>

        <u-form-group v-if="!isProjectFile" label="Edit Date Taken" name="date" :required="isSingleFileMode" class="mb-4" :ui="{help: 'text-xs'}">
          <core-date-range-picker
            v-model="localFileTakenAtModel"
            allow-exact-date-time
            :has-time-picker="false"
            :popper="{arrow: true}"
            overlay
            :ui="{
              container: 'max-w-[90%] sm:max-w-unset',
            }"
          >
            <template #default="{clearRange}">
              <u-button-group size="lg" :ui="{wrapper: {horizontal: 'flex w-full'}}">
                <u-button
                    :label="dateRangePickerButtonText"
                    color="gray"
                    variant="solid"
                    block
                    class="flex-1"
                />
                <u-button
                    v-if="localFileTakenAtModel?.val"
                    color="gray"
                    :icon="COMMON_ICONS.close"
                    variant="solid"
                    @click.stop="clearRange"
                />
              </u-button-group>
            </template>

            <template #under-date-picker="{currentStateKey}">
              <div v-if="currentStateKey === fuzzyDate.EXACT_DATE_AND_TIME" class="mt-2 text-gray-500 text-center text-xs">
                Note: Timestamps can only be edited in the lightbox.
              </div>
            </template>

            <template #other="{currentStateKey}">
              <div class="absolute-cover flex flex-col gap-2 items-center p-5 bg-white" v-if="currentStateKey !== fuzzyDate.EXACT_DATE_AND_TIME && !isFuzzyDateConfirmed">
                <div class="flex">
                  <u-icon name="i-ri-alert-line" class="text-amber text-5xl" />
                </div>
                <div class="flex-1 flex items-center">
                  <p class="text-center text-lg font-semibold">Setting Date Taken to a Fuzzy Date will reset the timestamps for all selected files. This cannot be undone. Continue?</p>
                </div>
                <div class="flex justify-center">
                  <u-button @click="confirmFuzzyDate">Yes, Continue</u-button>
                </div>
              </div>
            </template>

          </core-date-range-picker>
        </u-form-group>

        <u-form-group label="Add to Albums" class="mb-4">
          <template #hint>
            <u-button variant="link" size="sm" :padded="false" @click="chooseAlbum">Choose Album</u-button>
          </template>
          <album-select-menu size="lg" v-model="fileInfo.albums" />
        </u-form-group>

        <u-form-group label="Add Tags" class="mb-4">
          <tag-select-menu size="lg" v-model="fileInfo.tags" />
        </u-form-group>

        <u-form-group label="Add People" class="mb-4">
          <people-select-menu size="lg" v-model="fileInfo.people" />
        </u-form-group>

      </core-modal-body>

      <core-modal-footer>
        <u-button color="charcoal" variant="soft" @click="close">Cancel</u-button>
        <u-button type="submit" :loading="isSaving">Save Changes</u-button>
      </core-modal-footer>
    </u-form>
  </section>
</template>

<script setup>
  import {object, string, date} from 'yup';
  import {AlbumNavigatorModal} from '#components';

  const emit = defineEmits(['success']);

  const props = defineProps({
    files: Array,
    modal: Object
  });

  const filesStore = useFilesStore();
  const fileIds = props.files.map(f => f.forever_file_id || f.id);
  const isSingleFileMode = props.files.length === 1;
  const fileInfo = reactive({
    name: null,
    description: null,
    date: null, //for form validation only
    tags: [],
    albums: [],
    people: []
  });

  const isSaving = ref(false);
  const isProjectFile = computed(() => isSingleFileMode && props.files[0].is.project);

  const schema = object({
    name: isSingleFileMode ? string().required('This field is required.').max(FILE_NAME_MAXLENGTH, `Must be at most ${FILE_NAME_MAXLENGTH} characters`) : null,
    date: (isProjectFile.value || !isSingleFileMode) ? null : date().required('This field is required.')
  });

  const fuzzyDate = useFuzzyDate();
  const localFileTakenAtModel = ref(null);
  const dateRangePickerButtonText = computed(() => {
    if (!localFileTakenAtModel.value?.val) {
      return 'Select a Date';
    }

    //note: for display purposes only we format exact date and time the same as specific date because we don't let users set timestamp here
    return fuzzyDate.getFormattedString({
      date: localFileTakenAtModel.value?.val[0],
      type: (localFileTakenAtModel.value.type === fuzzyDate.EXACT_DATE_AND_TIME) ? fuzzyDate.SPECIFIC_DAY : localFileTakenAtModel.value.type
    });
  });

  watch(localFileTakenAtModel, newVal => {
    fileInfo.date = newVal.val && newVal.val[0]
  });

  function chooseAlbum() {
    useFModal({
      id: 'choose-album',
      component: AlbumNavigatorModal,
      eventHandlers: {
        async select(album) {
          fileInfo.albums.push(album);
          this.close();
        }
      }
    }).open();
  }

  async function save() {
    isSaving.value = true;

    try {
      const payload = Object.assign({}, fileInfo);

      if (localFileTakenAtModel.value?.val) {
        payload.date = localFileTakenAtModel.value.val[0];
        payload.dateType = localFileTakenAtModel.value.type;
      }

      payload.tagNames = fileInfo.tags?.map(t => t.name);
      delete payload.tags;

      payload.albumIds = fileInfo.albums?.map(a => a.id);
      delete payload.albums;

      payload.personIds = fileInfo.people?.map(p => p.id);
      delete payload.people;

      await useFileApi().updateFiles({ids: fileIds, updates: payload});
      filesStore.getFiles({reload: true});

      useSuccessToast().add({
        id: 'files-update-success',
        description: `Success! The ${fileIds.length} selected files were updated.`
      });

      if (filesStore.collectionDescriptor.contextType === FILE_CONTEXTS.tag) {
        fileInfo.tags.forEach(tag => filesStore.updateContext({contextId: tag.id, contextType: FILE_CONTEXTS.tag}));
      }
      if (filesStore.collectionDescriptor.contextType === FILE_CONTEXTS.album) {
        fileInfo.albums.forEach(album => filesStore.updateContext({contextId: album.id, contextType: FILE_CONTEXTS.album}));
      }
      if (filesStore.collectionDescriptor.contextType === FILE_CONTEXTS.person) {
        fileInfo.people.forEach(person => filesStore.updateContext({contextId: person.id, contextType: FILE_CONTEXTS.person}));
      }

      emit('success');

    } catch (e) {
      useErrorToast().add();
    }
  }

  function close() {
    props.modal.close();
  }

  const isFuzzyDateConfirmed = ref(isSingleFileMode); //note: when isSingleFileMode we initialize to confirmed and don't present to user

  function confirmFuzzyDate() {
    isFuzzyDateConfirmed.value = true;
  }

  onMounted(async () => {
    if (isSingleFileMode) {
      const file = props.files[0];

      fileInfo.name = file.name;

      const fileDetail = file.is.detail ? file : await useFileApi().getFileDetails([file.id]).then(r => r[0]);
      fileInfo.description = fileDetail.description;

      if (!isProjectFile.value) {
        localFileTakenAtModel.value.val = [fileDetail.taken_at];
        localFileTakenAtModel.value.type = fileDetail.taken_at_date_type;
      }
    }
  });

</script>
