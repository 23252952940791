<template>
  <div>
    <core-modal-header>Merge People?</core-modal-header>

    <core-modal-body>
      <p class="text-xl text-center">Are you sure you want to merge...</p>
      <div class="flex justify-center gap-8 my-8">
        <div class="w-2/5 max-w-2/5">
          <people-list-item
            :person="person"
            disable-edit
          />
        </div>

        <u-divider label="into" icon="i-ri-arrow-right-line" orientation="vertical" :ui="{label: 'text-lg font-semibold'}" />

        <div class="w-2/5 max-w-2/5">
          <people-list-item
            :person="toPerson"
            disable-edit
          />
        </div>
      </div>

      <u-alert
        color="red"
        :icon="COMMON_ICONS.information"
        title="This action cannot be undone."
        description="This action will permanently combine the person on the left with the person on the right. All information you've added to the left person will be lost."
      />

    </core-modal-body>

    <core-modal-footer>
      <u-button size="xl" color="charcoal" variant="soft" @click="modal.close()">Cancel</u-button>
      <u-button size="xl" :loading="isMerging" @click="merge">Merge People</u-button>
    </core-modal-footer>
  </div>
</template>

<script setup>
  const emit = defineEmits(['success']);
  const props = defineProps({
    person: Object,
    toPerson: Object,
    modal: Object
  });

  const peopleStore = usePeopleStore();
  const isMerging = ref(false);

  async function merge() {
    try {
      await peopleStore.mergePeople({
        source: props.person,
        destination: props.toPerson
      });

      props.modal.close();

      emit('success');
    } catch (e) {
      useErrorToast().add();
    }
  }

</script>
