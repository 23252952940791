<template>
  <u-popover overlay>
    <slot :clear-range="clearRange">
      <u-button-group :size="triggerSize" :ui="{wrapper: {horizontal: 'flex w-full'}}">
        <u-button
          :label="buttonDisplayText"
          :color="triggerColor"
          :variant="triggerVariant"
          block
          class="flex-1"
        />
        <u-button
          v-if="dateRange?.val"
          :color="triggerColor"
          :icon="COMMON_ICONS.close"
          :variant="triggerVariant"
          @click.stop="clearRange"
        />
      </u-button-group>
    </slot>

    <template #panel="{close}">
      <core-date-range-picker-manager
        v-model="dateRange"
        :year-range="yearRange"
        :has-time-picker="hasTimePicker"
        :allow-custom="allowCustom"
        :allow-exact-date-time="allowExactDateTime"
        @close="close"
        @update-date-type="onDateTypeUpdate"
      >
        <template #under-date-picker="{currentStateKey}">
          <slot name="under-date-picker" :current-state-key="currentStateKey" />
        </template>

        <template #other="{currentStateKey}">
          <slot name="other" :current-state-key="currentStateKey" />
        </template>
      </core-date-range-picker-manager>
    </template>
  </u-popover>
</template>

<script setup>
  const emit = defineEmits(['update-date-type']);
  const props = defineProps({
    yearRange: {
      type: Array,
      default: [1200, useDayjs().utc().year()]
    },
    triggerSize: {
      type: String,
      default: 'md'
    },
    triggerVariant: {
      type: String,
      default: 'outline'
    },
    triggerColor: {
      type: String,
      default: 'white'
    },
    hasTimePicker: {
      type: Boolean,
      default: true
    },
    allowCustom: Boolean,
    allowExactDateTime: Boolean
  });

  const fuzzyDate = useFuzzyDate();

  const dateRange = defineModel();

  dateRange.value = dateRange.value || {};

  const buttonDisplayText = computed(() => {
    if (!dateRange.value?.val) {
      return 'Select a Date Range';
    }

    return dateRange.value?.displayText || fuzzyDate.getFormattedString({date: dateRange.value?.val[0], type: dateRange.value.type});
  });

  function clearRange() {
    dateRange.value = {};
  }

  function onDateTypeUpdate(newDateType) {
    emit('update-date-type', newDateType);
  }
</script>
