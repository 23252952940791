<template>
  <div class="p-5 xs:p-7 sm:p-14">

    <client-only>
      <div class="w-11/12 overflow-hidden relative mb-8 mx-auto">
        <core-media-player
          src="https://forever-mars-ui.s3.amazonaws.com/video/f3/tutorial/how_to-declutter.mp4"
          poster-src="https://forever-mars-ui.s3.amazonaws.com/video/f3/tutorial/how_to-declutter.jpg"
        />
      </div>
    </client-only>

    <div class="flex justify-center mb-6">
      <h1 class="text-3xl font-semibold  text-center"><span class="text-gradient-blue inline-block">Welcome to Declutter!</span> 🎉</h1>
    </div>

    <p class="text-lg text-center text-charcoal-400">
      The Declutter Tool organizes photos by grouping them based on when they were taken. Filter by date range and time
      interval to review your collection easily. While not a dedupe tool, it helps you quickly decide which photos to
      keep or delete, making photo organization effortless.
    </p>

    <div class="mt-8 flex items-center flex-col justify-center">
      <u-button
        size="xl"
        @click="declutter"
        label="Get Started!"
        block
        class="max-w-64"
      />
    </div>

  </div>
</template>

<script setup>
  const props = defineProps({
    modal: Object
  });

  async function dismissOnboarding() {
    await usePreferencesStore().updateCurrentUserPreferences({[CURRENT_USER_PREFERENCES.onbDeclutterDismissed]: true});
  }

  function dismiss() {
    props.modal.close();
  }

  async function declutter() {
    await dismissOnboarding();
    useFileActions().declutter();
    dismiss();
  }
</script>

