<template>
  <div class="relative">
    <div class="flex py-2 justify-around items-center gap-2">
      <u-button
        variant="link"
        color="charcoal"
        size="xl"
        @click="close"
        label="Cancel"
        :ui="{
          variant: {
            link: 'dark:text-charcoal dark:hover:text-pcharcoal-700 dark:focus:text-charcoal-700'
          }
        }"
      />

      <div class="flex-1 flex justify-center">
        <u-select
          class="w-48"
          color="white"
          :options="states"
          v-model="currentStateKey"
          option-attribute="label"
          value-attribute="key"
          :ui="{color: {white: {outline: 'dark:bg-white dark:text-gray-900 dark:ring-gray-300 dark:focus:ring-primary-500'}}}"
        />
      </div>

      <u-button
        @click="apply"
        variant="link"
        size="xl"
        label="Apply"
        :ui="{
          variant: {
            link: 'dark:text-heroblue-500 dark:hover:text-heroblue-700 dark:focus:text-heroblue-700'
          }
        }"
      />
    </div>

    <u-divider :ui="dividerUI" />

    <div class="flex flex-col p-2 sm:p-4 z-40">
      <component
        class="flex-1 small"
        :is="currentState.component"
        v-model="internalDateRange"
        :year-range="yearRange"
        :has-time-picker="hasTimePicker"
      />
      <slot name="under-date-picker" :current-state-key="currentStateKey" />
    </div>

    <slot name="other" :current-state-key="currentStateKey" />
  </div>
</template>

<script setup>
  import cloneDeep from 'lodash.clonedeep';
  import {
    CoreDateRangePickerStateCustomRange,
    CoreDateRangePickerStateExactDateAndTime,
    CoreDateRangePickerStateSpecificDay,
    CoreDateRangePickerStateMonthYear,
    CoreDateRangePickerStateQuarterYear,
    CoreDateRangePickerStateYear,
    CoreDateRangePickerStateDecade,
    CoreDateRangePickerStateCentury
  } from '#components';
  import {waitFor} from '~/utils/flow-control.js';

  const emit = defineEmits(['close', 'update-date-type']);

  const props = defineProps({
    yearRange: {
      type: Array
    },
    hasTimePicker: {
      type: Boolean,
      default: true
    },
    allowCustom: Boolean,
    allowExactDateTime: Boolean
  });

  const dividerUI = {
    border: {
      base: 'dark:border-gray-200'
    }
  };

  const shouldShowMenu = ref(false);

  onMounted(async () => {
    await waitFor(1000);
    shouldShowMenu.value = true;
  });

  const dayJs = useDayjs();

  const dateRange = defineModel();
  const internalDateRange = ref();

  watch(dateRange, newVal => {
    internalDateRange.value = cloneDeep(newVal);
  },
  {
    immediate: true,
    deep: true
  });

  const fuzzyDate = useFuzzyDate();

  const states = [
    {
      label: FUZZY_DATE_LABELS[fuzzyDate.SPECIFIC_DAY],
      key: fuzzyDate.SPECIFIC_DAY,
      component: CoreDateRangePickerStateSpecificDay
    },
    {
      label: FUZZY_DATE_LABELS[fuzzyDate.MONTH_AND_YEAR],
      key: fuzzyDate.MONTH_AND_YEAR,
      component: CoreDateRangePickerStateMonthYear
    },
    {
      label: FUZZY_DATE_LABELS[fuzzyDate.QUARTER_AND_YEAR],
      key: fuzzyDate.QUARTER_AND_YEAR,
      component: CoreDateRangePickerStateQuarterYear
    },
    {
      label: FUZZY_DATE_LABELS[fuzzyDate.YEAR],
      key: fuzzyDate.YEAR,
      component: CoreDateRangePickerStateYear
    },
    {
      label: FUZZY_DATE_LABELS[fuzzyDate.DECADE],
      key: fuzzyDate.DECADE,
      component: CoreDateRangePickerStateDecade
    },
    {
      label: FUZZY_DATE_LABELS[fuzzyDate.CENTURY],
      key: fuzzyDate.CENTURY,
      component: CoreDateRangePickerStateCentury
    }
  ];

  if (props.allowCustom) {
    states.unshift({
      label: 'Date Range',
      key: 'custom-range',
      component: CoreDateRangePickerStateCustomRange,
      getDisplayValue() {
        return `${dayJs(internalDateRange.value?.val[0]).format('ll')} - ${dayJs(internalDateRange.value?.val[1]).format('ll')}`;
      }
    });
  }

  if (props.allowExactDateTime) {
    states.unshift({
      label: 'Exact Date & Time',
      key: fuzzyDate.EXACT_DATE_AND_TIME,
      component: CoreDateRangePickerStateExactDateAndTime
    });
  }

  const currentStateKey = ref(internalDateRange.value?.type || states[0].key);
  const currentState = computed(() => states.find(s => s.key === currentStateKey.value));

  watch(currentState, () => {
    internalDateRange.value = cloneDeep(dateRange.value);
  });

  watch(currentStateKey, newVal => emit('update-date-type', newVal));

  function close() {
    emit('close');
  }

  function apply() {
    if (!internalDateRange.value?.val) {
      return;
    }

    dateRange.value = {
      ...internalDateRange.value,
      type: currentStateKey.value,
      displayText: currentState.value.getDisplayValue && currentState.value.getDisplayValue()
    };
    close();
  }

</script>
