<template>
  <div class="flex flex-col h-full bg-gray-100/60 border-r border-r-gray-300 shadow-lg">

    <header class="border-b border-gray-200 py-2 px-4">
      <div class="flex justify-between items-center">

        <div>
          <u-button
            v-if="areFiltersApplied"
            label="Clear Filters"
            variant="link"
            color="black"
            size="sm"
            @click="clear"
            :padded="false"
            :ui="{font: 'font-medium'}"
          >
            <template #trailing>
              <u-chip color="charcoal" size="2xl" class="ml-2" :text="filterCount" />
            </template>
          </u-button>
          <h3 v-else class="flex items-center gap-2">
            <u-icon name="i-ri-equalizer-line" class="text-xl" />
            <span class="leading-none font-semibold mt-px">Filters</span>
          </h3>
        </div>
        <u-button
          icon="i-ri-contract-left-line"
          variant="ghost"
          color="gray"
          size="xs"
          @click="emit('toggle')"
          :ui="{icon: {base: 'text-charcoal/80 scale-125'}}"
        />
      </div>
    </header>

    <section class="p-2 sm:p-4 !pb-0 scrollbar-light flex-1 overflow-y-auto">

      <div class="relative">
        <transition name="f-fade">
          <div class="absolute inset-0 bg-white/40 z-40" v-if="isClearing || isLoadingFiles || isBuffering" />
        </transition>

        <div class="rounded bg-white select-none cursor-pointer p-3 mb-3 flex items-center gap-2  border border-charcoal-300/50" v-if="isContextOwner" @click="favoriteActive = !favoriteActive">
          <u-icon class="text-2xl" :class="favoriteActive ? 'text-red-400' : 'text-charcoal-300'" :name="favoriteActive ? COMMON_ICONS.favoriteFill : COMMON_ICONS.favoriteLine" />
          <span class="font-semibold text-base flex-1 mt-px">Favorites</span>
          <u-checkbox v-model="favoriteActive" @click.stop />
        </div>

        <u-accordion
          :ui="accordionConfig"
          :items="filterPanelItems"
          color="charcoal"
          variant="ghost"
          size="xl"
          class="my-3"
        >

          <template #default="{item, open}">
            <button class="flex font-semibold p-3 items-center gap-2">

              <u-icon v-if="item.icon" :name="item.icon" class="text-xl text-charcoal-300" />
              <span class="text-nowrap leading-none mt-px">{{item.label}}</span>

              <span class="flex-1 flex justify-end gap-1 flex-wrap">

              <template v-if="!open && item.slot === 'sort-by'">
                <u-badge color="blue" size="xs" :label="order?.label" />
              </template>

              <template v-if="!open && item.slot === 'asset-type'">
                <u-badge
                  v-for="type in fileTypes.filter(t => t.active)"
                  color="blue"
                  size="xs"
                  :label="type.label"
                />
              </template>

              <template v-else-if="!open && item.slot === 'date-range'">
                <u-badge v-if="dateRange?.displayText" color="blue" size="xs">
                  {{dateRange.displayText}}
                </u-badge>
              </template>

              <template v-else-if="!open && item.slot === 'people' && people.length">
                <u-tooltip :text="people.map(p => p.name).join(', ')" :popper="{placement: 'top'}">
                  <u-badge color="blue" size="xs" :ui="{base: 'text-nowrap'}">{{people.length}} Selected</u-badge>
                </u-tooltip>
              </template>

              <template v-else-if="!open && item.slot === 'albums' && albums.length">
                <u-tooltip :text="albums.map(a => a.name).join(', ')" :popper="{placement: 'top'}">
                  <u-badge color="blue" size="xs" :ui="{base: 'text-nowrap'}">{{albums.length}} Selected</u-badge>
                </u-tooltip>
              </template>

              <template v-else-if="!open && item.slot === 'tags' && tags.length">
                <u-tooltip :text="tags.map(t => t.name).join(', ')" :popper="{placement: 'top'}">
                  <u-badge color="blue" size="xs" :ui="{base: 'text-nowrap'}">{{tags.length}} Selected</u-badge>
                </u-tooltip>
              </template>

            </span>

              <u-icon name="i-ri-arrow-down-s-line" class="text-xl transition-all" :class="open && '-rotate-180'" />
            </button>
          </template>

          <template #sort-by>
            <div class="grid grid-cols-2 gap-3">
              <template v-for="option in sortOptions" :key="option.paramName">
                <u-button
                  :label="option.label"
                  :color="option.paramName === order.paramName ? 'charcoal' : 'gray'"
                  variant="solid"
                  size="lg"
                  block
                  @click="setSort(option)"
                />
              </template>

              <u-button
                v-if="shouldShowCustomSortEdit"
                variant="link"
                block
                class="underline"
                label="Edit Custom Order"
                @click="openCustomSorter"
              />
            </div>
          </template>


          <template #asset-type>
            <div class="grid grid-cols-3 gap-3">
              <template v-for="type in fileTypes" :key="type.key">
                <u-button
                  :label="type.label"
                  :color="type.active ? 'charcoal' : 'gray'"
                  variant="solid"
                  size="lg"
                  block
                  @click="type.active = !type.active"
                />
              </template>
            </div>
          </template>

          <template #date-range>
            <core-date-range-picker
              v-model="dateRange"
              class="text-charcoal"
              trigger-size="lg"
              allow-custom
              overlay
              :popper="{ arrow: true, placement: screenMd ? 'right' : 'bottom'}"
              :ui="{
                background: 'dark:bg-gray-700 bg-white',
                arrow: {
                  background: 'dark:before:bg-white',
                }
              }"
            />
          </template>

          <template #people>
            <people-select-menu v-model="people" disable-create />
          </template>

          <template #tags>
            <tag-select-menu v-model="tags" disable-create />
          </template>

          <template #albums>
            <album-select-menu v-model="albums" disable-create />
          </template>

        </u-accordion>

        <div class="grid grid-cols-2 gap-3 my-3 rounded" v-if="shouldShowOrganization">
          <template v-for="type in organizationTypes" :key="type.key">
            <u-button
              @click="type.active = !type.active"
              :label="type.label"
              variant="solid"
              :color="type.active ? 'charcoal' : 'gray'"
              size="xl"
              block
            />
          </template>
        </div>
      </div>

      <footer class="sticky bottom-0 pb-4 bg-gray-100/50">
        <u-button
          :label="submitButtonText"
          size="xl"
          block
          :loading="isClearing || isLoadingFiles"
          :disabled="filesCountFallback === 0"
          @click="emit('toggle')"
          class="shadow shadow-gray-300"
        />
      </footer>
    </section>
  </div>
</template>

<script setup>
  import {storeToRefs} from 'pinia';
  import isEqual from 'lodash.isequal';
  import {makeFilesStore} from "~/stores/files.js";
  import {useScreenSize} from '~/composables/core/screen-size.js';

  const props = defineProps({
    filesStoreId: String
  });

  const storeFiltersState = inject('storeFiltersState', false);

  const emit = defineEmits(['toggle']);

  const albumsStore = useAlbumsStore();
  const filesStore = props.filesStoreId
    ? makeFilesStore(props.filesStoreId)()
    : useFilesStore();

  const {collectionDescriptor, filesCount, filesCountFallback, isLoadingFiles, isBuffering, areFiltersApplied, filterCount} = storeToRefs(filesStore);

  const isContextOwner = computed(() => Boolean(!collectionDescriptor.value?.context || collectionDescriptor.value?.context.isOwnedByUser));
  const shouldShowOrganization = computed(() => !Boolean(collectionDescriptor.value?.context));


  const shouldShowCustomSort = computed(() => collectionDescriptor.value?.contextType === FILE_CONTEXTS.album);

  const sortOptions = ref([...(shouldShowCustomSort.value ? FILE_SORT_OPTIONS_WITH_CUSTOM : FILE_SORT_OPTIONS)]);
  const hasCustomSort = computed(() => sortOptions.value.find(option => option.paramName === 'custom'));
  const shouldShowCustomSortEdit = computed(() => hasCustomSort.value && isContextOwner.value && collectionDescriptor.value?.context.files_count > 1);

  const {md: screenMd} = useScreenSize();

  const order = ref();

  function setSort(newOrder) {
    order.value = newOrder;
  }

  function openCustomSorter() {
    emit('toggle');

    const modal = useFModal({
      id: 'files-custom-sort',
      component: defineAsyncComponent(() => import('./custom-sorter.vue')),
      size: 'fullscreen',
      showClose: false,
      props: {
        album: albumsStore.album
      },
      eventHandlers: {
        done() {
         modal.close();
        }
      }
    });

    modal.open();
  }

  // configure the accordion panels
  const filterPanelItems = computed(() => {
    const filterItems = [
      {
        label: 'Sort By',
        slot: 'sort-by',
        icon: 'i-ri-sort-desc'
      },
      {
        label: 'Media Type',
        defaultOpen: true,
        slot: 'asset-type',
        icon: 'i-ri-file-text-line'
      },
      {
        label: order.value?.filterDateLabel,
        slot: 'date-range',
        icon: 'i-ri-calendar-2-line'
      }
    ];

    if (isContextOwner.value) {
      filterItems.push(
        {
          label: 'People',
          slot: 'people',
          icon: COMMON_ICONS.person
        },
        {
          label: 'Tags',
          slot: 'tags',
          icon: COMMON_ICONS.tag
        },
        {
          label: 'Albums',
          slot: 'albums',
          icon: COMMON_ICONS.album
        }
      );
    }

    return filterItems;
  });

  const accordionConfig = {
    wrapper: 'flex flex-col gap-3',
    container: 'bg-white rounded border border-charcoal-300/50',
    item: {
      base: 'bg-white rounded',
      padding: 'p-3 pt-1.5'
    }
  };

  const favoriteActive = ref(false);

  const fileTypes = ref([
    {
      label: 'Photo',
      active: false,
      key: FILE_TYPES.photo
    },
    {
      label: 'Video',
      active: false,
      key: FILE_TYPES.video
    },
    {
      label: 'Audio',
      active: false,
      key: FILE_TYPES.audio
    },
    {
      label: 'Document',
      active: false,
      key: FILE_TYPES.document
    },
    {
      label: 'Project',
      active: false,
      key: FILE_TYPES.project
    }
  ]);

  const organizationTypes = ref([
    {
      label: 'Not In Any Album',
      active: false,
      key: FILE_FILTERS.notInAlbum
    },
    {
      label: 'Untagged',
      active: false,
      key: FILE_FILTERS.notTagged
    }
  ]);

  const dayjs = useDayjs();
  const date = ref();
  const dateRange = ref({});

  const tags = ref([]);
  const albums = ref([]);
  const people = ref([]);

  const submitButtonText = computed(() => {
    if (isLoadingFiles.value || isClearing.value) {
      return '';
    } else if (filesCountFallback.value === 0) {
      return 'No Results';
    } else if (areFiltersApplied.value) {
      return `Show ${filesCountFallback.value || ''} Results`;
    } else {
      return 'Show All Files';
    }
  });

  //update internal model from store
  watch(collectionDescriptor, () => {
    favoriteActive.value = Boolean(collectionDescriptor.value?.organization?.includes(FILE_FILTERS.favorites));

    fileTypes.value.forEach(ft => ft.active = Boolean(collectionDescriptor.value?.fileTypes?.includes(ft.key)));
    organizationTypes.value.forEach(ot => ot.active = Boolean(collectionDescriptor.value?.organization?.includes(ot.key)));

    dateRange.value = collectionDescriptor.value?.dateRange || {};
    tags.value = collectionDescriptor.value?.tags || tags.value;
    albums.value = collectionDescriptor.value?.albums || albums.value;
    people.value = collectionDescriptor.value?.people || people.value;
    order.value = collectionDescriptor.value?.order || order.value;
  },
  {
    immediate: true,
    deep: true
  });

  // compose date model using a format to be given to the store
  const filtersModel = computed(() => {
    const organization = (() => {
      const o = organizationTypes.value.filter(ot => ot.active).map(ot => ot.key);

      if (favoriteActive.value) {
        o.push(FILE_FILTERS.favorites);
      }

      return o;
    })();

    return {
      fileTypes: fileTypes.value.filter(ft => ft.active).map(ft => ft.key),
      organization,

      dateRange: dateRange.value,
      tags: tags.value,
      albums: albums.value,
      people: people.value,
      order: order.value
    };
  });

  const isClearing = ref(false);

  async function clear() {
    isClearing.value = true;
    await filesStore.clearFilters({storeCollection: storeFiltersState});
    isClearing.value = false;
  }

  async function filter() {
    const newCollectionDescriptor = {
      ...collectionDescriptor.value,
      ...filtersModel.value
    };

    if (isEqual(newCollectionDescriptor, collectionDescriptor.value)) {
      return;
    }

    try {
      await filesStore.getFiles({
        ...newCollectionDescriptor,
        storeCollection: storeFiltersState
      });
    } catch (e) {
      useErrorToast().add();
    }
  }

  watch(filtersModel, filter);
</script>
