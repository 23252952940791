<template>
  <ul class="list-none text-base text-charcoal overflow-y-auto grid grid-cols-3 gap-1.5 sm:gap-2.5 scrollbar-light pr-2 sm:-mr-2" ref="centuryPickerEl">
    <li
      v-for="century in centuries"
      class="cursor-pointer flex-center text-sm px-4 py-3 transition-colors border border-gray-200 leading-none"
      :class="[
        {'bg-heroblue text-white font-semibold': century.val === selection},
        {'hover:bg-black/5 border-gray-300': century.val !== selection}
      ]"
      @click="setCentury(century)"
      :data-century-value="century.val"
    >
      {{century.label}}
    </li>
  </ul>
</template>

<script setup>
  const props = defineProps({
    yearRange: {
      type: Array
    }
  });

  const dayJs = useDayjs();
  const dateRange = defineModel();
  const centuries = Array.from(Array(Math.ceil((props.yearRange[1] - props.yearRange[0]) / 100)), (n, i) => {
    const val = Math.ceil(props.yearRange[1] / 100) - i;

    return {
      label: getCenturySuffix(val),
      val
    }
  });
  const selection = computed({
    get() {
      const theDate = dateRange.value?.val ? dayJs(dateRange.value?.val[0]) : dayJs();
      const theYear = theDate.utc().year();
      const century = centuries.find(century => (((century.val - 1) * 100) + 1) <= theYear)

      return century?.val;
    },
    set(value) {
      const startYear = ((value - 1) * 100) + 1;
      const endYear = value * 100;

      dateRange.value.val = [
        dayJs().utc().year(startYear).startOf('year'),
        dayJs().utc().year(endYear).endOf('year')
      ];
    }
  });

  function setCentury(century) {
    selection.value = century.val;
  }

  const centuryPickerEl = ref();

  onMounted(() => {
    selection.value = selection.value; //note: needed to update the dateRange model to the appropriate range

    const itemEl = centuryPickerEl.value.querySelector(`[data-century-value="${selection.value}"]`);
    if (itemEl) {
      itemEl.scrollIntoView({ behavior: 'instant', block: 'start' });
    }
  });

</script>
