<template>
  <div>
    <core-modal-header>Hide <span class="font-semibold">{{person.name || 'Unnamed'}}</span>?</core-modal-header>

    <core-modal-body>
      <p>Hiding this person will remove them from view, but their files will stay associated and can be shown again later.</p>
    </core-modal-body>

    <core-modal-footer>
      <u-button color="charcoal" size="xl" variant="soft" @click="modal.close()">Cancel</u-button>
      <u-button @click="confirm" size="xl" :loading="isSaving">Hide Person</u-button>
    </core-modal-footer>

  </div>
</template>

<script setup>
  const emit = defineEmits(['success']);
  const props = defineProps({
    person: Object,
    modal: Object
  });

  const isSaving = ref(false);

  async function confirm() {
    try {
      isSaving.value = true;

      await usePeopleStore().updatePerson({
        id: props.person.id,
        updates: {
          display_status: PEOPLE_DISPLAY_STATUS.hidden
        }
      });
      emit('success');
    } catch (e) {
      useErrorToast().add();
    } finally {
      isSaving.value = false;
    }
  }
</script>
