<template>
  <ul class="list-none text-base text-charcoal overflow-y-auto max-h-48 grid grid-cols-3 gap-1.5 sm:gap-2.5 scrollbar-light pr-2 sm:-mr-2" ref="yearPickerEl">
    <li
      v-for="year in years"
      class="cursor-pointer flex-center text-sm px-4 py-3 transition-colors border border-gray-200 leading-none"
      :class="[
        {'bg-heroblue text-white font-semibold': year === selection},
        {'hover:bg-black/5 border-gray-300': year !== selection}
      ]"
      @click="setYear(year)"
      :data-year-value="year"
    >
      {{year}}
    </li>
  </ul>
</template>

<script setup>
  const props = defineProps({
    yearRange: {
      type: Array
    }
  });

  const dayJs = useDayjs();
  const dateRange = defineModel();
  const years = Array.from(Array((props.yearRange[1] - props.yearRange[0]) + 1), (n, i) => props.yearRange[1] - i);
  const selection = computed({
    get() {
      const theDate = dateRange.value?.val ? dayJs(dateRange.value?.val[0]) : dayJs();

      return theDate.utc().year();
    },
    set(value) {
      dateRange.value.val = [
        dayJs().utc().year(value).startOf('year'),
        dayJs().utc().year(value).endOf('year')
      ];
    }
  });

  function setYear(year) {
    selection.value = year;
  }

  const yearPickerEl = ref();

  onMounted(async () => {
    selection.value = selection.value; //note: needed to update the dateRange model to the appropriate range

    const itemEl = yearPickerEl.value.querySelector(`[data-year-value="${selection.value}"]`);
    if (itemEl) {
      itemEl.scrollIntoView({ behavior: 'instant', block: 'start' });
    }

  });
</script>
